import React, { useState, Component } from "react";
import Header from "../Common/Header";
import TableMenu from "../Common/TableMenu";
import Footer from "../Common/Footer";
import { Table, Nav, NavDropdown, Form, NavLink } from "react-bootstrap";
import AddDeleteTableRows from "../Common/AddDeleteTableRows";


const type = 'checkbox';

export default class Token extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchentcoin1: true,
      merchentcoin2: false,
      merchentcoin3: false,
      loading: true,
      byDefaultCBValue: true
    };
  }
  getcheckedvalue = (event) => {
    this.showState(event.target.id);
  }

  showState = (status) => {
    if (status === "merchentcoin1") {
      this.setState({ merchentcoin1: !this.state.merchentcoin1 });
    }
    else if (status === "merchentcoin2") {
      this.setState({ merchentcoin2: !this.state.merchentcoin2 });
    }
    else if (status === "merchentcoin3") {
      this.setState({ merchentcoin3: !this.state.merchentcoin3 });
    }
  }

  getData() {
    setTimeout(() => {
      this.setState({

        loading: false,
      });
    }, 1000);
  }
  async componentDidMount() {
    this.getData();

  }
  render() {
    return (
      <>

        {(
          <div>
            <Header />
            

                  


            <div class="crn_bal d-flex justify-content-end  align-items-center">
              <div class="gry_txt">Current Ballance</div> <span>$ 460,000</span>
            </div>
            <div class="container-fluid mt-3">
              <div class="fin_agr pb-4">

                <div class="titlebar d-flex justify-content-between align-items-center">
                  <h1>MERCHANT COINS : </h1>
               
                </div>
                <TableMenu showState={this.showState} />
                <div className = "menuitem mc_co">
               
                <Form.Check type={type} id={`merchentcoin1`} >
                  <Form.Check.Input  type={type} isInvalid onChange={(event) => { this.setState({ byDefaultCBValue: event.target.checked }); this.getcheckedvalue(event) }}
                  checked={this.state.byDefaultCBValue} />
                  <Form.Check.Label>{`MERCHENT COIN 1`}</Form.Check.Label >
                  </Form.Check>

                  <Form.Check type={type} id={`merchentcoin2`}>
                  <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                  <Form.Check.Label>{`MERCHENT COIN 2`}</Form.Check.Label>
                  </Form.Check>

                  <Form.Check type={type} id={`merchentcoin3`}>
                  <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                  <Form.Check.Label>{`MERCHENT COIN 3`}</Form.Check.Label>
                  </Form.Check>  

                  {/* <NavDropdown.Item href="/Token">UTILITY TOKENS</NavDropdown.Item>
                  <NavDropdown.Item href="/Token">SECURITY TOKENS</NavDropdown.Item>
                  <NavDropdown.Item href="/Token">NFTS</NavDropdown.Item> 
                              */}
              </div>

                {this.state.merchentcoin1 && <>
                  <div className="addtable">
                  <h1 className = "heading-token"> MERCHENT COIN 1 </h1>
                  <AddDeleteTableRows />
                  <Table  striped bordered hover size="sm">
              
                    <thead>
                      <tr>
                        <th>Action </th>
                        <th>Token ID</th>
                        <th>Weight</th>
                        <th>Assay</th>
                        <th>Production Date</th>
                        <th>Security Feature</th>
                        <th>Length</th>
                        <th>Width</th>
                        <th>Height</th>
                        <th>Location</th>
                        <th>Price</th>
                        <th>Owner</th>
                        <th>Second Holder</th>
                        <th>Encumbered</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>

                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>3</td>
                        <td>398.000</td>
                        <td>999.9</td>
                        <td>2022-01-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>

                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>4</td>
                        <td>399.000</td>
                        <td>999.9</td>
                        <td>2021-06-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>5</td>
                        <td>400.000</td>
                        <td>999.9</td>
                        <td>2021-10-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">1</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-11-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">999</td>
                        <td>398.000</td>
                        <td>999.9</td>
                        <td>2022-02-16</td>
                        <td>Photo</td>
                        <td>250.0</td>
                        <td>70.0</td>
                        <td>35.0</td>
                        <td>VA</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td>None</td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">9</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-10-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td>HSBC</td>
                        <td>true</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">10</td>
                        <td>400.000</td>
                        <td>999.9</td>
                        <td>2022-01-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>18</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-12-05</td>
                        <td>Photo</td>
                        <td>118.0</td>
                        <td>53.0</td>
                        <td>8.0</td>
                        <td>VA</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                    </tbody>

                  </Table>
                  </div>
                </>
                }
                {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                {this.state.merchentcoin2 && <>
                  <div className="addtable">
                  <h1 className = "heading-token"> MERCHENT COIN 2 </h1>
                  <AddDeleteTableRows />
                  <Table className="tablemargin" striped bordered hover size="sm">
                 
                    <thead>
                      <tr>
                        <th>Action </th>
                        <th>Token ID</th>
                        <th>Weight</th>
                        <th>Assay</th>
                        <th>Production Date</th>
                        <th>Security Feature</th>
                        <th>Length</th>
                        <th>Width</th>
                        <th>Height</th>
                        <th>Location</th>
                        <th>Price</th>
                        <th>Owner</th>
                        <th>Second Holder</th>
                        <th>Encumbered</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>

                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>3</td>
                        <td>398.000</td>
                        <td>999.9</td>
                        <td>2022-01-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>

                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>4</td>
                        <td>399.000</td>
                        <td>999.9</td>
                        <td>2021-06-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>5</td>
                        <td>400.000</td>
                        <td>999.9</td>
                        <td>2021-10-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">1</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-11-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">999</td>
                        <td>398.000</td>
                        <td>999.9</td>
                        <td>2022-02-16</td>
                        <td>Photo</td>
                        <td>250.0</td>
                        <td>70.0</td>
                        <td>35.0</td>
                        <td>VA</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td>None</td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">9</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-10-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td>HSBC</td>
                        <td>true</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">10</td>
                        <td>400.000</td>
                        <td>999.9</td>
                        <td>2022-01-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>18</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-12-05</td>
                        <td>Photo</td>
                        <td>118.0</td>
                        <td>53.0</td>
                        <td>8.0</td>
                        <td>VA</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                    </tbody>

                  </Table>

                  </div>
                  
                </>
                }
                {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                {this.state.merchentcoin3 && <>
                  <div className="addtable">
                  <h1 className = "heading-token">MERCHENT COIN 3</h1>
                  <AddDeleteTableRows />
                  <Table className="tablemargin" striped bordered hover size="sm">                  
                    <thead>
                      <tr>
                        <th>Action </th>
                        <th>Token ID</th>
                        <th>Weight</th>
                        <th>Assay</th>
                        <th>Production Date</th>
                        <th>Security Feature</th>
                        <th>Length</th>
                        <th>Width</th>
                        <th>Height</th>
                        <th>Location</th>
                        <th>Price</th>
                        <th>Owner</th>
                        <th>Second Holder</th>
                        <th>Encumbered</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>

                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>3</td>
                        <td>398.000</td>
                        <td>999.9</td>
                        <td>2022-01-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>

                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>4</td>
                        <td>399.000</td>
                        <td>999.9</td>
                        <td>2021-06-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>5</td>
                        <td>400.000</td>
                        <td>999.9</td>
                        <td>2021-10-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">1</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-11-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">999</td>
                        <td>398.000</td>
                        <td>999.9</td>
                        <td>2022-02-16</td>
                        <td>Photo</td>
                        <td>250.0</td>
                        <td>70.0</td>
                        <td>35.0</td>
                        <td>VA</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td>None</td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">9</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-10-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td>HSBC</td>
                        <td>true</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">10</td>
                        <td>400.000</td>
                        <td>999.9</td>
                        <td>2022-01-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>18</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-12-05</td>
                        <td>Photo</td>
                        <td>118.0</td>
                        <td>53.0</td>
                        <td>8.0</td>
                        <td>VA</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                    </tbody>

                  </Table>

                  </div>
                  
                </>
                }
                {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}




              </div>


            </div>
            <Footer />
          </div>
        )}
      </>
    );
  }
}
