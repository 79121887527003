import "./App.css";
import "./Assets/css/bootstrap.min.css";
import "./Assets/css/magnific-popup.css";
import "./Assets/css/animate.min.css";
import "./Assets/css/icofont.min.css";
// import './Assets/css/font-awesome.min.css';
import "./Assets/css/pages.css";
import "./Assets/css/style.css";
import "./Assets/css/responsive.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Components/Common/Routes";
import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme';
import { GlobalStyles } from './global';

function App() {
  const [theme, setTheme] = useState('light');
  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
      
    } else {
      setTheme('light');
      
    }
  }


  return (

  

    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <>
        <GlobalStyles />
        {/* // Pass the toggle functionality to the button */}
      <label class="switch swpos">
        <input type="checkbox" onClick={toggleTheme} />
        <span class="slider round"></span> 
      </label> 
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
      </>

  </ThemeProvider>
  
  );
}

export default App;
