
// theme.js
export const lightTheme = {
    body: '#FFFFFF',
    text: '#000 !important',
    toggleBorder: '#FFF',
    gradient: 'linear-gradient(#39598A, #79D7ED)',
  }

  
  
  export const darkTheme = {
    body: '#000000',
    text: '#fff !important',
    toggleBorder: '#6B8096',
    gradient: 'linear-gradient(#091236, #1E215D)',
  }