import React from 'react';
import {NavLink} from 'react-router-dom';
import {Nav} from "react-bootstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

export default class MainMenu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle1 = this.toggle1.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);
    this.toggle4 = this.toggle4.bind(this);
    this.toggle5 = this.toggle5.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseEnterp = this.onMouseEnterp.bind(this);
    this.onMouseLeavep = this.onMouseLeavep.bind(this);
    this.onMouseEntert = this.onMouseEntert.bind(this);
    this.onMouseLeavet = this.onMouseLeavet.bind(this);
    this.onMouseEntera = this.onMouseEntera.bind(this);
    this.onMouseLeavea = this.onMouseLeavea.bind(this);
    this.onMouseEnterb = this.onMouseEnterb.bind(this);
    this.onMouseLeaveb = this.onMouseLeaveb.bind(this);
    this.state = {
      dropdownOpen1: false,
      dropdownOpen2: false,
      dropdownOpen3: false,
    };
  }

  toggle1() {
    this.setState((prevState) => ({
      dropdownOpen1: !prevState.dropdownOpen1,
    }));
  }

  toggle2() {
    this.setState((prevState) => ({
      dropdownOpen2: !prevState.dropdownOpen2,
    }));
  }

  toggle3() {
    this.setState((prevState) => ({
      dropdownOpen3: !prevState.dropdownOpen3,
    }));
  }

  toggle4() {
    this.setState((prevState) => ({
      dropdownOpen4: !prevState.dropdownOpen4,
    }));
  }

  toggle5() {
    this.setState((prevState) => ({
      dropdownOpen5: !prevState.dropdownOpen5,
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen1: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen1: false });
  }

  onMouseEnterp() {
    this.setState({ dropdownOpen2: true });
  }

  onMouseLeavep() {
    this.setState({ dropdownOpen2: false });
  }

  onMouseEntert() {
    this.setState({ dropdownOpen3: true });
  }

  onMouseLeavet() {
    this.setState({ dropdownOpen3: false });
  }


  onMouseEntera() {
    this.setState({ dropdownOpen4: true });
  }

  onMouseLeavea() {
    this.setState({ dropdownOpen4: false });
  }

  onMouseEnterb() {
    this.setState({ dropdownOpen5: true });
  }

  onMouseLeaveb() {
    this.setState({ dropdownOpen5: false });
  }




  render() {
    return (      
      <div className='hovmenu'>
        <NavLink className="menu" to="/">BANK ACCOUNT</NavLink>
        <NavLink className="menu" to="/DigitalAssets">DIGITAL ASSETS</NavLink>
        <Dropdown
          className="d-inline-block"
          onMouseOver={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          isOpen={this.state.dropdownOpen1}
          toggle={this.toggle1}
        >
          <DropdownToggle caret>Finance</DropdownToggle>
          <DropdownMenu>
          <DropdownItem href="#">Open</DropdownItem>
          <DropdownItem href="#">Accepted</DropdownItem>
          <DropdownItem href="#">Obligation - Cash</DropdownItem>
          <DropdownItem href="#">Obligation - Gold</DropdownItem>
          <DropdownItem href="#">Settled</DropdownItem>
          <DropdownItem href="#">Request</DropdownItem> 
          </DropdownMenu>
           
        </Dropdown>
        <Dropdown
          className="d-inline-block"
          onMouseOver={this.onMouseEnterp}
          onMouseLeave={this.onMouseLeavep}
          isOpen={this.state.dropdownOpen2}
          toggle={this.toggle2}
        >
          <DropdownToggle caret>TRADING</DropdownToggle>
          <DropdownMenu>
          <DropdownItem href="#">Trading & Credit Relationships</DropdownItem>
          <DropdownItem href="#">Trade - Buy</DropdownItem>
          <DropdownItem href="#">Trade - Sell</DropdownItem>
          <DropdownItem href="#">Trade - Requests</DropdownItem>
          <DropdownItem href="#">Trade - Confirm</DropdownItem>
          <DropdownItem href="#">Trade - Completed</DropdownItem>   
          </DropdownMenu>        
        </Dropdown>        
     
        <Dropdown
          className="d-inline-block"
          onMouseOver={this.onMouseEntert}
          onMouseLeave={this.onMouseLeavet}
          isOpen={this.state.dropdownOpen3}
          toggle={this.toggle3}
        >
          <DropdownToggle caret>RETAILS</DropdownToggle>
          <DropdownMenu>           
          <DropdownItem href="#">Retail - Buy</DropdownItem>
          <DropdownItem href="#">Retail - Requests</DropdownItem>
          <DropdownItem href="#">Retail - Confirm</DropdownItem>
          <DropdownItem href="#">Retail - Completed</DropdownItem>  
          </DropdownMenu>
        </Dropdown>

        <Dropdown
          className="d-inline-block"
          onMouseOver={this.onMouseEntera}
          onMouseLeave={this.onMouseLeavea}
          isOpen={this.state.dropdownOpen4}
          toggle={this.toggle4}
        >
          <DropdownToggle caret>COLLATERAL</DropdownToggle>
          <DropdownMenu>            
          <DropdownItem href="#">Collateral - Request</DropdownItem>
          <DropdownItem href="#">Collateral - Complete</DropdownItem>  
          </DropdownMenu>
        </Dropdown>

 

        <Nav.Link to="#">CAPACITY</Nav.Link>

         

        <Dropdown
          className="d-inline-block"
          onMouseOver={this.onMouseEnterb}
          onMouseLeave={this.onMouseLeaveb}
          isOpen={this.state.dropdownOpen5}
          toggle={this.toggle5}
        >
          <DropdownToggle caret>TRANSFER</DropdownToggle>
          <DropdownMenu>
          <DropdownItem href="#">Transfer In - Request</DropdownItem>
          <DropdownItem href="#">Transfer In - Confirm Ownership</DropdownItem>
          <DropdownItem href="#">Transfer Out</DropdownItem>  
          </DropdownMenu>
        </Dropdown>



      </div>    
    );
  }
}