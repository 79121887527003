import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import $ from 'jquery';
$(function () {
  // Scroll Event
  $(window).on('scroll', function () {
    var scrolled = $(window).scrollTop();
    if (scrolled > 300) $('.back-to-top').addClass('active');
    if (scrolled < 300) $('.back-to-top').removeClass('active');
  });
  // Click Event
  $('.back-to-top').on('click', function () {
    $("html, body").animate({
      scrollTop: "0"
    }, 100);
  });

//   var nav = $('nav');
// 	var navHeight = nav.outerHeight();
	$('.navbar-toggler').on('click', function () {
		if (!$('#mainNav').hasClass('navbar-reduce')) {
			$('#mainNav').addClass('navbar-reduce');
		}
	});

	// Navbar Menu Reduce 
	$(window).trigger('scroll');
	$(window).on('scroll', function () {
		var pixels = 50;
		var top = 1200;
		if ($(window).scrollTop() > pixels) {
			$('.navbar-expand-lg').addClass('navbar-reduce');
			$('.navbar-expand-lg').removeClass('navbar-trans');
		} else {
			$('.navbar-expand-lg').addClass('navbar-trans');
			$('.navbar-expand-lg').removeClass('navbar-reduce');
		}
		if ($(window).scrollTop() > top) {
			$('.scrolltop-mf').fadeIn(1000, "easeInOutExpo");
		} else {
			$('.scrolltop-mf').fadeOut(1000, "easeInOutExpo");
		}
	});
  $(document).click(function (event) {
    var _opened = $(".navbar-collapse").hasClass("navbar-collapse justify-content-end collapse show");
    if (_opened === true && !$(event.target).hasClass("navbar-toggler")) {
        $(".navbar-toggler").click();
    }
  });
  
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


