 import BTC from '../../Assets/img/icons/1.png';
 import ETH from '../../Assets/img/icons/1027.png';
 import BNB from '../../Assets/img/icons/1839.png';
 import USDC from '../../Assets/img/icons/3408.png';
 import USDT from '../../Assets/img/icons/825.png';
 import XRP from '../../Assets/img/icons/52.png';
 import LUNA from '../../Assets/img/icons/4172.png';
 import ADA from '../../Assets/img/icons/2010.png';
 import SOL from '../../Assets/img/icons/5426.png';
 import AVAX from '../../Assets/img/icons/5805.png';
 import BUSD from '../../Assets/img/icons/4687.png';
 import DOT from '../../Assets/img/icons/6636.png';
 import DOGE from '../../Assets/img/icons/74.png';
 import UST from '../../Assets/img/icons/7129.png';
 import SHIB from '../../Assets/img/icons/5994.png';
 import MATIC from '../../Assets/img/icons/3890.png';
 import WBTC from '../../Assets/img/icons/3717.png';
 import DAI from '../../Assets/img/icons/4943.png';
 import CRO from '../../Assets/img/icons/3635.png';
 import ATOM from '../../Assets/img/icons/3794.png';
 import LTC from '../../Assets/img/icons/2.png';


export const IconsList = { 'BTC': BTC, 'ETH': ETH,  
'BNB' : BNB ,
'USDC': USDC,
'XRP' : XRP ,
'LUNA': LUNA,
'ADA' : ADA ,
'SOL' : SOL ,
'AVAX' : AVAX,
'BUSD' : BUSD,
'DOT'  : DOT ,
'DOGE' : DOGE,
'UST' : UST ,
'SHIB' : SHIB,
'MATIC' : MATIC,
'WBTC' : WBTC,
'DAI' : DAI ,
'CRO' : CRO ,
'ATOM' :ATOM,
'LTC' : LTC,
'USDT' : USDT,
};