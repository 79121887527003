import React, { Component } from "react";
import LogoWhite from "../../Assets/img/crowdpoint-logo.png";
import Usericon from "../../Assets/img/user-icon.png";
import { Navbar, NavDropdown } from "react-bootstrap";
import { FaHeart } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import MainMenu from './MainMenu';
export default class ToggleSwitch extends Component {
  constructor(props) {
    super(props)
    this.state = { isToggleOn: false }

    this.handleClick = this.handleClick.bind(this)
  }


  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn
    }))
  }

  render() {
    return (
      <>


        <header class="container-fluid">
          <div class="row align-items-center">

            <div class="col-lg-2 mainmenu deskview">
              <a class="navbar-brand js-scroll" href={"/"}>
                <img src={LogoWhite} class="white-logo logo" alt="logo" />
              </a>
            </div>

            <div class="col-lg-8 mainmenu">
              <Navbar bg="light" expand="lg">
                <a class="navbar-brand js-scroll mobview" href={"/"}>
                  <img src={LogoWhite} class="white-logo logo" alt="logo" />
                </a>


                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <MainMenu />
                  </Navbar.Collapse>
                </Navbar>



            </div>
            <div class="col-lg-2 text-end deskview">
              <div className="trade">
                {/* <i class="bi bi-person-fill"></i> Trader-B - Trader <i class="bi bi-caret-down-fill"></i> */}
                {/* <div className="offbtn"><img src={Offbtn} alt="user" /></div> */}

                {/* <div onClick={this.handleClick} className={this.state.isToggleOn ? 'ToggleSwitch activebg' : 'ToggleSwitch'}> */}
                {/* <div className={this.state.isToggleOn ? 'knob active' : 'knob'} /> */}
                {/* {this.state.isToggleOn ? 'now it\'s on' : 'now it\'s off'} */}
                {/* </div> */}

                <div className="userpanel"><img src={Usericon} class="usericon" alt="user" />
                </div>
              </div>

            </div>
          </div>


        </header>

      </>
    );
  }
}
