import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <div class="mt-5 mb-5">
          <div class="container">
            <div class="row align-items-center">
            <div class="col-md-12">
           

                  {/* Empty */}

             

              </div>
             
              
            </div>
          </div>
        </div>
      
      </div>
    );
  }
}
