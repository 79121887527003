import axios from "axios";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import TableMenu from "./TableMenu";
import { useMemo } from "react";
import Pagination from "../utils/Pagination";
import "./itemsList.css";
import { IconsList } from "../utils/Icons";



let PageSize = 10;

export function CryptoCurrency() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  useEffect(() => {
    axios
      .get(
       // "http://127.0.0.1:9665/fetchAPI?endpoint=https://data.messari.io/api/v1/assets?fields=id,slug,symbol,metrics/market_data/price_usd",
        "https://data.messari.io/api/v1/assets?",
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
           // "X-CMC_PRO_API_KEY": "575c83a6-f543-4cac-83d0-e7188ecf4b34",
          },
        }
      )
      .then((response) => {
        const items = response.data.data;
        setData(items);
        setCurrentPage(1);
      });
  }, []);

  // useEffect(() => {
  //   fetch("/v1/cryptocurrency/map", { headers: { "X-CMC_PRO_API_KEY": "575c83a6-f543-4cac-83d0-e7188ecf4b34" } })
  //     .then((response) => {
  //       return response.json();
  //     }).then((response) => {
  //       const items = response.data;
  //       setData(items);
  //       setCurrentPage(1);
  //     });
  // }, []);

  return (
    <>
     <Header />
    
     <div class="crn_bal d-flex justify-content-end  align-items-center">
        <div class="gry_txt">Current Ballance</div> <span>$ 460,000</span>
    </div>

    <div class="container-fluid mt-3">
        <div class="fin_agr pb-4">
       
        <div class="titlebar d-flex justify-content-between align-items-center">
        <h1>CRYPTO CURRENCY : </h1>
         
    </div>  

    <TableMenu />

   
      <table border="1" className="crytotable">
      <thead>
    <tr>
        <th>  </th>
        <th>NAME </th>
        <th>SYMBOL</th>
        {/* <th>id</th> */}
        <th>PRICE USD</th>
        <th>PRICE ETH</th>
        <th>VOLUME LAST 24 HRS.</th>
        <th>REAL VOLUME LAST 24 HRS.</th>
        <th>VOLUME LAST 24 HRS. OVERSTATEMENT MULTIPLE</th>
        <th>% CHANGE USD LAST 1 HOUR</th> 
        <th>% CHANGE BTC LAST 1 HOUR</th> 
        <th>% CHANGE ETH LAST 1 HOUR</th>
        <th>% CHANGE USD LAST 24 HRS.</th> 
        <th>% CHANGE BTC LAST 24 HRS.</th> 
        <th>% CHANGE ETH LAST 24 HRS.</th> 
    </tr>
  </thead>
        <tbody>
          {currentTableData.map(item => {
            return (
              <tr>
                    { <td className="coinlogo-width"><img className="coinlogo" src={IconsList[item.symbol]}></img></td> }
                <td>{item.slug.toUpperCase()}</td>
                <td>{item.symbol}</td>
                {/* {/ <td>{item.id}</td> /} */}
                <td>{item.metrics.market_data.price_usd.toFixed(2)}</td>
                <td>{item.metrics.market_data.price_eth.toFixed(2)}</td>
                <td style={{color: item.metrics.market_data.volume_last_24_hours.toFixed(2) < 0 ? "#E80101" : "#049237"}}> {item.metrics.market_data.volume_last_24_hours.toFixed(2)}</td>
                <td style={{color: item.metrics.market_data.real_volume_last_24_hours.toFixed(2) < 0 ? "#E80101" : "#049237"}}> {item.metrics.market_data.real_volume_last_24_hours.toFixed(2)}</td>
                <td style={{color: item.metrics.market_data.volume_last_24_hours_overstatement_multiple.toFixed(2) < 0 ? "#E80101" : "#049237"}}> {item.metrics.market_data.volume_last_24_hours_overstatement_multiple.toFixed(2)}</td>
                <td style={{color: item.metrics.market_data.percent_change_usd_last_1_hour.toFixed(2) < 0 ? "#E80101" : "#049237"}}> {item.metrics.market_data.percent_change_usd_last_1_hour.toFixed(2)}%</td>
                <td style={{color: item.metrics.market_data.percent_change_btc_last_1_hour.toFixed(2) < 0 ? "#E80101" : "#049237"}}> {item.metrics.market_data.percent_change_btc_last_1_hour.toFixed(2)}%</td>
                <td style={{color: item.metrics.market_data.percent_change_eth_last_1_hour.toFixed(2) < 0 ? "#E80101" : "#049237"}}> {item.metrics.market_data.percent_change_eth_last_1_hour.toFixed(2)}%</td>
                <td style={{color: item.metrics.market_data.percent_change_usd_last_24_hours.toFixed(2) < 0 ? "#E80101" : "#049237"}}> {item.metrics.market_data.percent_change_usd_last_24_hours.toFixed(2)}%</td>
                <td style={{color: item.metrics.market_data.percent_change_btc_last_24_hours.toFixed(2) < 0 ? "#E80101" : "#049237"}}> {item.metrics.market_data.percent_change_btc_last_24_hours.toFixed(2)}%</td>
                <td style={{color: item.metrics.market_data.percent_change_eth_last_24_hours.toFixed(2) < 0 ? "#E80101" : "#049237"}}> {item.metrics.market_data.percent_change_eth_last_24_hours.toFixed(2)}%</td>
          {/*  
                          <td>{item.metrics.market_data.volume_last_24_hours.toFixed(2)}</td>
                <td>{item.metrics.market_data.real_volume_last_24_hours.toFixed(2)}</td>
          <td>{item.metrics.market_data.volume_last_24_hours_overstatement_multiple.toFixed(2)}</td>
                 <td> {(item.metrics.market_data.percent_change_usd_last_1_hour.toFixed(2))}%</td> 
                <td>{item.metrics.market_data.percent_change_btc_last_1_hour.toFixed(2)}%</td> 
                <td>{item.metrics.market_data.percent_change_eth_last_1_hour.toFixed(2)}%</td>
                <td>{item.metrics.market_data.percent_change_usd_last_24_hours.toFixed(2)}%</td>
                <td>{item.metrics.market_data.percent_change_btc_last_24_hours.toFixed(2)}%</td>
                <td>{item.metrics.market_data.percent_change_eth_last_24_hours.toFixed(2)}%</td> */}
                
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={data.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      />
      </div>   

</div>
       <Footer />
    </>
  );
}
