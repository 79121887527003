import React, { Component } from "react";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
import BannerImage from "../Assets/img/Banner-image.png";


export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  getData() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  }
  async componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <>
       
        { (
          <div>
            <Header />
 <div class="container-fluid mt-3 px-0">
  
  <img src={BannerImage} class="banner-logo logo" alt="logo" />
  </div>

  <div class="container mt-5">
 <div className="row">


  </div>
 
    </div>
         <Footer />
          </div>
        )}
      </>
    );
  }
}
