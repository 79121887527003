import React from "react";
import { Route, Routes as Switch } from "react-router-dom";
import Home from "../../Pages/Home";
import GoldPage from "../../Pages/Gold-Page";
import DigitalAssets from "../../Pages/DigitalAssets";
import Token from "../tabledata/token";
import Bond from "../tabledata/Bond";
import Commodities from "../tabledata/Commodities";
import Merchantcoins from "../tabledata/Merchantcoins";
import {CryptoCurrency} from "./CryptoCurrency";
export default function Routes() {
  return (
    <Switch>
      <Route path="/" element={<Home />} />
      <Route path="/gold-page" element={<GoldPage />} />
      <Route path="/DigitalAssets" element={<DigitalAssets />} />
      <Route path="/Token" element={<Token />} />
      <Route path="/CryptoCurrency" element={<CryptoCurrency />} />
      <Route path="/Bond" element={<Bond />} />
      <Route path="/Commodities" element={<Commodities />} />
      <Route path="/Merchantcoins" element={<Merchantcoins />} />
     </Switch>
  );
}
