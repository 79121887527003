import React, { Component } from "react";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
import AddDeleteTableRows from "../Components/Common/AddDeleteTableRows";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  getData() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  }
  async componentDidMount() {
    this.getData();
    }
  render() {
    return (
      <>
        
        { (
          <div>
            <Header />
            <AddDeleteTableRows />

      <div class="container-fluid mt-3">
        <div class="fin_agr pb-4">

        <div class="titlebar d-flex justify-content-between">
        <h1>Finance Agreement</h1>
        <button><i class="bi bi-plus"></i> Create New</button>
    </div>    

        <div class="d-flex justify-content-around border-bottom hd_txt">
        <div>Action</div>
        <div>Status</div>
        <div>Agreement ID</div>
        <div>Proposal Date</div>
        <div>Troy Oz</div>
        <div>Amount (USD)</div>
        <div>Receiver</div>
        <div>Refiner</div>
        <div>Delivery Date</div> 
        <div>Cash Settlement Date</div> 
    </div> 


    
  <div class="crn_bal d-flex justify-content-end  align-items-center pt-4 px-4">

   <div> Rows per page:</div> 

    <select class="selectfield" name="numberpage" id="numberpage">
        <option value="volvo">10</option>
        <option value="saab">20</option>
        <option value="opel">30</option>
        <option value="audi">40</option>
      </select>

</div>  



</div>   

    </div>
            
     <Footer />
          </div>
        )}
      </>
    );
  }
}
