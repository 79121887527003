import React, { useState, Component } from "react";
import Header from "../Common/Header";
import TableMenu from "../Common/TableMenu";
import Footer from "../Common/Footer";



export default class Token extends Component {
  constructor(props) {
    super(props);
    this.state = {
          loading: true,
      
    };
  }
 


  getData() {
    setTimeout(() => {
      this.setState({

        loading: false,
      });
    }, 1000);
  }
  async componentDidMount() {
    this.getData();

  }
  render() {
    return (
      <>

        {(
          <div>
            <Header />
            
            <div class="crn_bal d-flex justify-content-end  align-items-center">
              <div class="gry_txt">Current Ballance</div> <span>$ 460,000</span>
            </div>
            <div class="container-fluid mt-3">
              <div class="fin_agr pb-4">

                <div class="titlebar d-flex justify-content-between align-items-center">
                  <h1>BOND : </h1>
               
                </div>
                <TableMenu />
              </div>

              <h1 className="mt-5">** TO BE DONE ** </h1>

            </div>
            <Footer />
          </div>
        )}
      </>
    );
  }
}
