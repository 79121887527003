import React, { useState } from "react";
import TableRows from "./TableRows";
import { FaHeart, FaPlus} from "react-icons/fa";

function AddDeleteTableRows(){
    const [rowsData, setRowsData] = useState([]);
 
    const addTableRows = ()=>{
  
        const rowsInput={
            fullName:'',
            emailAddress:'',
            salary:''  
        } 
        setRowsData([...rowsData, rowsInput])
      
    }
   const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
   }
 
   const handleChange = (index, evnt)=>{
    
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  
 
 
}
    return(
        <>
                <button className="btn btn-outline-success" onClick={addTableRows} > <FaPlus /> Create New</button>
                <table className="table tablepos">
                   <tbody>
                   <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
                   </tbody> 
                </table>
                 
               
                 </>    
    )
}
export default AddDeleteTableRows