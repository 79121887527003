import React, { useState, Component } from "react";
import Header from "../Common/Header";
import TableMenu from "../Common/TableMenu";
import Footer from "../Common/Footer";
import { Table, Nav, NavDropdown, Form, NavLink } from "react-bootstrap";
import AddDeleteTableRows from "../Common/AddDeleteTableRows";


const type = 'checkbox';
const type1 = 'Dropdown';

export default class Token extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agricultural: true,
      energy: false,
      livestockmeat: false,
      metals: false,
      loading: true,
      byDefaultCBValue: true
    };
  }
  getcheckedvalue = (event) => {
    this.showState(event.target.id);
  }

  showState = (status) => {
    if (status === "agricultural") {
      this.setState({ agricultural: !this.state.agricultural });
    }
    else if (status === "energy") {
      this.setState({ energy: !this.state.energy });
    }
    else if (status === "livestockmeat") {
      this.setState({ livestockmeat: !this.state.livestockmeat });
    }
    else if (status === "metals") {
        this.setState({ metals: !this.state.metals });
      }
  }

  getData() {
    setTimeout(() => {
      this.setState({

        loading: false,
      });
    }, 1000);
  }
  async componentDidMount() {
    this.getData();

  }
  render() {
    return (
      <>

        {(
          <div>
            <Header />
            

                  


            <div class="crn_bal d-flex justify-content-end  align-items-center">
              <div class="gry_txt">Current Ballance</div> <span>$ 460,000</span>
            </div>
            <div class="container-fluid mt-3">
              <div class="fin_agr pb-4">

                <div class="titlebar d-flex justify-content-between align-items-center">
                  <h1>COMMODITIES : </h1>
               
                </div>
                <TableMenu showState={this.showState} />
                <div className = "menuitem comd">
               
                <Form.Check type={type} id={`agricultural`} >
                  <Form.Check.Input  type={type} isInvalid onChange={(event) => { this.setState({ byDefaultCBValue: event.target.checked }); this.getcheckedvalue(event) }}
                  checked={this.state.byDefaultCBValue} />
                  <Form.Check.Label>{`AGRICULTURAL`}</Form.Check.Label >
                  
                    {this.state.agricultural && <>
                 
                 <div className="ddm">
                     
              
              <Form.Check type={type} id={`COFFEE`} >
                <Form.Check.Input  type={type} isInvalid onChange={(event) => { this.setState({ byDefaultCBValue: event.target.checked }); this.getcheckedvalue(event) }}
                checked={this.state.byDefaultCBValue} />
                <Form.Check.Label>{`COFFEE`}</Form.Check.Label >
          
                </Form.Check>

                <Form.Check type={type} id={`CORN`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`CORN`}</Form.Check.Label>
                </Form.Check>

                <Form.Check type={type} id={`LUMBER`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`LUMBER | MEAT`}</Form.Check.Label>
                </Form.Check> 

                <Form.Check type={type} id={`COTTON`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`COTTON`}</Form.Check.Label>
                </Form.Check>  
                <Form.Check type={type} id={`MILK`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`MILK`}</Form.Check.Label>
                </Form.Check>  
                <Form.Check type={type} id={`RICE`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`RICE`}</Form.Check.Label>
                </Form.Check>  
                <Form.Check type={type} id={`SOYBEANS`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`SOYBEANS`}</Form.Check.Label>
                </Form.Check>  
                <Form.Check type={type} id={`TEA`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`TEA`}</Form.Check.Label>
                </Form.Check>  


            </div>

               </>
               }
            </Form.Check>
                  <Form.Check type={type} id={`energy`}>
                  <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                  <Form.Check.Label>{`ENERGY`}</Form.Check.Label>

                  {this.state.energy && <>
                 
                 <div className="ddm">
              
              <Form.Check type={type} id={`COAL`} >
                <Form.Check.Input  type={type} isInvalid onChange={(event) => { this.setState({ byDefaultCBValue: event.target.checked }); this.getcheckedvalue(event) }}
                checked={this.state.byDefaultCBValue} />
                <Form.Check.Label>{`COAL`}</Form.Check.Label >
          
                </Form.Check>
                <Form.Check type={type} id={`CRUDE OIL`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`CRUDE OIL`}</Form.Check.Label>
                </Form.Check>

                <Form.Check type={type} id={`DIESEL FUEL`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`DIESEL FUEL`}</Form.Check.Label>
                </Form.Check>

                <Form.Check type={type} id={`GASOLINE`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`GASOLINE`}</Form.Check.Label>
                </Form.Check>


                <Form.Check type={type} id={`HEATING OIL`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`HEATING OIL`}</Form.Check.Label>
                </Form.Check>

                <Form.Check type={type} id={`KEROSENE`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`KEROSENE | MEAT`}</Form.Check.Label>
                </Form.Check> 

                <Form.Check type={type} id={`NATURAL GAS`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`NATURAL GAS`}</Form.Check.Label>
                </Form.Check>  
                <Form.Check type={type} id={`POWER`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`POWER`}</Form.Check.Label>
                </Form.Check>  
                <Form.Check type={type} id={`PROPANE`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`PROPANE`}</Form.Check.Label>
                </Form.Check>  
                <Form.Check type={type} id={`PETROCHEMICALS`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`PETROCHEMICALS`}</Form.Check.Label>
                </Form.Check>  
                <Form.Check type={type} id={` RENEWABLE ENERGY CREDITS`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{` RENEWABLE ENERGY CREDITS`}</Form.Check.Label>
                </Form.Check>  

                
            </div>

               </>
               }



                  </Form.Check>

                  <Form.Check type={type} id={`livestockmeat`}>
                  <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                  <Form.Check.Label>{`LIVESTOCK | MEAT`}</Form.Check.Label>
                  
                  {this.state.livestockmeat && <>
                 
                    <div className="ddm">
                     
                <Form.Check type={type} id={`BEEF`} >
                <Form.Check.Input  type={type} isInvalid onChange={(event) => { this.setState({ byDefaultCBValue: event.target.checked }); this.getcheckedvalue(event) }}
                checked={this.state.byDefaultCBValue} />
                <Form.Check.Label>{`BEEF`}</Form.Check.Label >
                </Form.Check>

                <Form.Check type={type} id={`CATTLE`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`CATTLE`}</Form.Check.Label>
                </Form.Check>

                <Form.Check type={type} id={`PORK BELLIES`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{` PORK BELLIES | MEAT`}</Form.Check.Label>
                </Form.Check> 

                <Form.Check type={type} id={`SALMON`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`SALMON`}</Form.Check.Label>
                </Form.Check>  

                <Form.Check type={type} id={`SHRIMP`}>
                <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                <Form.Check.Label>{`SHRIMP`}</Form.Check.Label>
                </Form.Check>  

                
            </div>

               </>
               }
                  </Form.Check> 

                  <Form.Check type={type} id={`metals`}>
                  <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
                  <Form.Check.Label>{`METALS`}</Form.Check.Label>
                  
                  {this.state.metals && <>
                 
                 <div className="ddm">
                  
             <Form.Check type={type} id={`GOLD`} >
             <Form.Check.Input  type={type} isInvalid onChange={(event) => { this.setState({ byDefaultCBValue: event.target.checked }); this.getcheckedvalue(event) }}
             checked={this.state.byDefaultCBValue} />
             <Form.Check.Label>{`GOLD`}</Form.Check.Label >
             </Form.Check>

             <Form.Check type={type} id={`COBALT`}>
             <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
             <Form.Check.Label>{`COBALT`}</Form.Check.Label>
             </Form.Check>

             <Form.Check type={type} id={`COPPER`}>
             <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
             <Form.Check.Label>{`COPPER`}</Form.Check.Label>
             </Form.Check> 

             <Form.Check type={type} id={`NICKEL`}>
             <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
             <Form.Check.Label>{`NICKEL`}</Form.Check.Label>
             </Form.Check>  

             <Form.Check type={type} id={`PALLADIUM`}>
             <Form.Check.Input type={type} isInvalid onChange={this.getcheckedvalue} />
             <Form.Check.Label>{`PALLADIUM`}</Form.Check.Label>
             </Form.Check>  

             
         </div>

            </>
            }
 </Form.Check>  

                  
                            
              </div>

                {/* {this.state.agricultural && <>
                  <h1 className = "heading-token"> AGRICULTURAL </h1>
                  <Table  striped bordered hover size="sm">
              
                    <thead>
                      <tr>
                        <th>Action </th>
                        <th>Token ID</th>
                        <th>Weight</th>
                        <th>Assay</th>
                        <th>Production Date</th>
                        <th>Security Feature</th>
                        <th>Length</th>
                        <th>Width</th>
                        <th>Height</th>
                        <th>Location</th>
                        <th>Price</th>
                        <th>Owner</th>
                        <th>Second Holder</th>
                        <th>Encumbered</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>

                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>3</td>
                        <td>398.000</td>
                        <td>999.9</td>
                        <td>2022-01-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>

                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>4</td>
                        <td>399.000</td>
                        <td>999.9</td>
                        <td>2021-06-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>5</td>
                        <td>400.000</td>
                        <td>999.9</td>
                        <td>2021-10-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">1</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-11-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">999</td>
                        <td>398.000</td>
                        <td>999.9</td>
                        <td>2022-02-16</td>
                        <td>Photo</td>
                        <td>250.0</td>
                        <td>70.0</td>
                        <td>35.0</td>
                        <td>VA</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td>None</td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">9</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-10-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td>HSBC</td>
                        <td>true</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">10</td>
                        <td>400.000</td>
                        <td>999.9</td>
                        <td>2022-01-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>18</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-12-05</td>
                        <td>Photo</td>
                        <td>118.0</td>
                        <td>53.0</td>
                        <td>8.0</td>
                        <td>VA</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                    </tbody>

                  </Table>
                  <AddDeleteTableRows />
                </>
                } */}
                {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                {/* {this.state.energy && <>
                  <h1 className = "heading-token"> ENERGY </h1>
                  <Table className="tablemargin" striped bordered hover size="sm">
                 
                    <thead>
                      <tr>
                        <th>Action </th>
                        <th>Token ID</th>
                        <th>Weight</th>
                        <th>Assay</th>
                        <th>Production Date</th>
                        <th>Security Feature</th>
                        <th>Length</th>
                        <th>Width</th>
                        <th>Height</th>
                        <th>Location</th>
                        <th>Price</th>
                        <th>Owner</th>
                        <th>Second Holder</th>
                        <th>Encumbered</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>

                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>3</td>
                        <td>398.000</td>
                        <td>999.9</td>
                        <td>2022-01-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>

                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>4</td>
                        <td>399.000</td>
                        <td>999.9</td>
                        <td>2021-06-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>5</td>
                        <td>400.000</td>
                        <td>999.9</td>
                        <td>2021-10-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">1</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-11-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">999</td>
                        <td>398.000</td>
                        <td>999.9</td>
                        <td>2022-02-16</td>
                        <td>Photo</td>
                        <td>250.0</td>
                        <td>70.0</td>
                        <td>35.0</td>
                        <td>VA</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td>None</td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">9</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-10-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td>HSBC</td>
                        <td>true</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">10</td>
                        <td>400.000</td>
                        <td>999.9</td>
                        <td>2022-01-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>18</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-12-05</td>
                        <td>Photo</td>
                        <td>118.0</td>
                        <td>53.0</td>
                        <td>8.0</td>
                        <td>VA</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                    </tbody>

                  </Table>
                  <AddDeleteTableRows />
                </>
                } */}
                {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                
                {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

{/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                {/* {this.state.metals && <>
                  <h1 className = "heading-token">METALS</h1>
                  <Table className="tablemargin" striped bordered hover size="sm">
                  
                    <thead>
                      <tr>
                        <th>Action </th>
                        <th>Token ID</th>
                        <th>Weight</th>
                        <th>Assay</th>
                        <th>Production Date</th>
                        <th>Security Feature</th>
                        <th>Length</th>
                        <th>Width</th>
                        <th>Height</th>
                        <th>Location</th>
                        <th>Price</th>
                        <th>Owner</th>
                        <th>Second Holder</th>
                        <th>Encumbered</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>

                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>3</td>
                        <td>398.000</td>
                        <td>999.9</td>
                        <td>2022-01-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>

                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>4</td>
                        <td>399.000</td>
                        <td>999.9</td>
                        <td>2021-06-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>5</td>
                        <td>400.000</td>
                        <td>999.9</td>
                        <td>2021-10-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">1</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-11-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">999</td>
                        <td>398.000</td>
                        <td>999.9</td>
                        <td>2022-02-16</td>
                        <td>Photo</td>
                        <td>250.0</td>
                        <td>70.0</td>
                        <td>35.0</td>
                        <td>VA</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td>None</td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">9</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-10-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td>HSBC</td>
                        <td>true</td>
                      </tr>
                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td class="lockicon">10</td>
                        <td>400.000</td>
                        <td>999.9</td>
                        <td>2022-01-05</td>
                        <td>Photo</td>
                        <td>272.0</td>
                        <td>94.5</td>
                        <td>51.5</td>
                        <td>Loco London</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                      <tr>
                        <td> <a class="vw-lnk" href="#">View</a></td>
                        <td>18</td>
                        <td>401.000</td>
                        <td>999.9</td>
                        <td>2021-12-05</td>
                        <td>Photo</td>
                        <td>118.0</td>
                        <td>53.0</td>
                        <td>8.0</td>
                        <td>VA</td>
                        <td>1,800.00</td>
                        <td>TradeB</td>
                        <td></td>
                        <td>False</td>
                      </tr>

                    </tbody>

                  </Table>
                  <AddDeleteTableRows />
                </>
                } */}
                {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}


              </div>


            </div>
            <Footer />
          </div>
        )}
      </>
    );
  }
}