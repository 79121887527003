function TableRows({rowsData, deleteTableRows, handleChange}) {
    return(
        
        rowsData.map((data, index)=>{
            const {	TokenID,	Weight,	Assay,	Production_Date,Security_Feature,Length,	Width,	Height,	Location,	Price,	Owner,	Second_Holder,	Encumbered}= data;
        return(
                <tr key={index}>
        <td align="left" className="view"> <a class="vw-lnk" href="#">View</a></td>
    {/* <td> <input placeholder = "sdfsf" type="text" value={Action} onChange={(evnt)=>(handleChange(index, evnt))} name="Action" className="form-control"/> </td> */}
    <td align="left" className="token"><input type="text" value={TokenID}  onChange={(evnt)=>(handleChange(index, evnt))} name="TokenID" className="form-control "/> </td>
    <td align="left" className="weight"><input type="text" value={Weight}  onChange={(evnt)=>(handleChange(index, evnt))} name="Weight" className="form-control" /> </td>
    <td align="left" className="assay"> <input type="text" value={Assay} onChange={(evnt)=>(handleChange(index, evnt))} name="Assay" className="form-control"/> </td>
    <td align="left" className="piddate"><input type="text" value={Production_Date}  onChange={(evnt)=>(handleChange(index, evnt))} name="Production_Date" className="form-control"/> </td>
    <td align="left" className="secftr"><input type="text" value={Security_Feature}  onChange={(evnt)=>(handleChange(index, evnt))} name="Security_Feature" className="form-control" /> </td>
    <td align="left" className="len"> <input type="text" value={Length} onChange={(evnt)=>(handleChange(index, evnt))} name="Length" className="form-control"/> </td>
    <td align="left" className="wdt"><input type="text" value={Width}  onChange={(evnt)=>(handleChange(index, evnt))} name="Width" className="form-control"/> </td>
    <td align="left" className="hgt"><input type="text" value={Height}  onChange={(evnt)=>(handleChange(index, evnt))} name="Height" className="form-control" /> </td>
    <td align="left" className="location"> <input type="text" value={Location} onChange={(evnt)=>(handleChange(index, evnt))} name="Location" className="form-control"/> </td>
    <td align="left" className="price"><input type="text" value={Price}  onChange={(evnt)=>(handleChange(index, evnt))} name="Price" className="form-control"/> </td>
    <td className="owner"><input type="text" value={Owner}  onChange={(evnt)=>(handleChange(index, evnt))} name="Owner" className="form-control" /> </td>
    <td align="left" className="sechol"> <input type="text" value={Second_Holder} onChange={(evnt)=>(handleChange(index, evnt))} name="Second_Holder" className="form-control"/> </td>
    <td align="left" className="encum"><input type="text" value={Encumbered}  onChange={(evnt)=>(handleChange(index, evnt))} name="Encumbered" className="form-control"/> </td>
    
    <td align="left" className="delbtn"><button className="btn btn-outline-danger" onClick={()=>(deleteTableRows(index))}>x</button></td>
            </tr>
            )
        })
   
    )
    
}
export default TableRows;